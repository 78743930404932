import { DateTime } from "luxon";
import * as TimeUtils from "@/store/TimeUtils";
import TileService from "@/components/dashboard/tiles/TileService";
import UserService from "@/components/user/UserService";
import { SHIFT, PRODUCTION_RUN, HOUR } from "@/store/TimeUtils";
import FactoryService from "@/components/factory/FactoryService";

export default {
  namespaced: true,

  state: {
    activeFactory: null,
    activeFactoryDataSourceAlerts: [],

    // Properties used by the TimeFrameDatePicker
    timeFrame: SHIFT,
    date: null,
    dateData: {
      hour: null,
      shiftIndex: null,
      isLastShift: null,
      runIndex: null,
      isLastRun: null,
    },
    isLiveData: true,

    // Properties used by views
    startDateTime: null, // DateTime
    endDateTime: null, // DateTime
    currentDateTime: null, // DateTime
    offsetFromMidnightMillis: 0,
    workShiftCoverage: [],
    productionRunCoverage: [],

    /**
     * The isTimeRangeReady is used by views to know when to re-fetch data.
     *
     * Views watch this property and when it switches from 'false' to 'true', they should
     * re-fetch data with the new values for 'startDateTime' and 'endDateTime'
     */
    isTimeRangeReady: true,

    factoryUsers: [],
    factoryTags: [],
  },

  getters: {
    activeFactory(state) {
      return state.activeFactory;
    },
    activeFactoryId(state) {
      return state.activeFactory?.id;
    },
    factoryTags(state) {
      return state.factoryTags;
    },
    activeFactoryProductionUnits(state) {
      return state.activeFactory?.productionUnits ?? [];
    },
    activeFactoryDataSourceAlerts(state) {
      return state.activeFactoryDataSourceAlerts;
    },
    timeFrame(state) {
      return state.timeFrame;
    },
    currentDateTime(state) {
      return state.currentDateTime;
    },
    date(state) {
      return state.date;
    },
    dateData(state) {
      return state.dateData;
    },
    isLiveData(state) {
      return state.isLiveData;
    },
    factoryUsers(state) {
      return state.factoryUsers;
    },
    startDateTime(state) {
      return state.startDateTime;
    },
    startISO(state) {
      return state.startDateTime?.toISO();
    },
    endDateTime(state) {
      if (state.endDateTime) {
        // production day and hour will set a static end time
        return state.endDateTime;
      }
      if (state.currentDateTime && state.startDateTime && state.currentDateTime?.toMillis() <= state.startDateTime?.toMillis()) {
        /**
         * shift and production run will set a null end time
         * before returning the current date time, we have to make sure the browser current date time
         * is not before the start time of the production run / shift that was set by the API
         *
         * if it is, return the start date time plus 1 second until the browser current date time
         * catches up to the production run / shift start time which should be on the next call.
         */
        return state.startDateTime.plus({ seconds: 1 });
      }
      // if current date time is after the production run / shift start time, it is safe to return
      return state.currentDateTime;
    },
    endISO(state) {
      if (state.endDateTime) {
        return state.endDateTime.toISO();
      }
      if (state.currentDateTime && state.startDateTime && state.currentDateTime?.toMillis() <= state.startDateTime?.toMillis()) {
        return state.startDateTime.plus({ seconds: 1 }).toISO();
      }
      return state.currentDateTime?.toISO();
    },
    offsetFromMidnightMillis(state) {
      return state.offsetFromMidnightMillis;
    },
    durationMillis(state) {
      const start = state.startDateTime?.toMillis();
      const end = state.endDateTime ? state.endDateTime.toMillis() : state.currentDateTime?.toMillis();
      if (!start) return 0;
      return end - start;
    },
    isTimeRangeReady(state) {
      return state.isTimeRangeReady;
    },
  },

  actions: {
    async setActiveFactory({ getters, commit, dispatch }, { activeFactory, optionalTimeFrame, isSilent }) {
      if (activeFactory.id !== getters.activeFactory?.id) {
        commit("setActiveFactory", activeFactory);

        // set the time frame data on active factory set
        // if date is null, it means this is the first factory set
        // so default to today and PRODUCTION_DAY timeframe
        // if date is NOT null, the timeframe has already been set,
        // so keep the same one with the new factory
        const date = TimeUtils.getBusinessDateTime(
          DateTime.now().setZone(activeFactory.timezone),
          activeFactory.productionDayMinutesFromMidnight,
          activeFactory.isCalendarDayBusinessDay,
        );
        // Default time frame is SHIFT
        const timeFrame = optionalTimeFrame ?? SHIFT;
        let hour = null;
        if (timeFrame === HOUR) {
          hour = DateTime.now()
            .setZone(activeFactory.timezone)
            .set({ minute: 0, second: 0, millisecond: 0 })
            .get("hour");
        }

        await dispatch("setTimeFrame", {
          timeFrame: timeFrame,
          date: date,
          hour: hour,
          shiftIndex: 0,
          runIndex: 0,
          isLastShift: timeFrame === SHIFT,
          isLastRun: timeFrame === PRODUCTION_RUN,
          isSilent,
        });

        dispatch("fetchFactoryTags", activeFactory.id);
      }
    },
    async setTimeFrame(
      { state, commit, dispatch, rootGetters },
      {
        timeFrame,
        date,
        hour,
        shiftIndex,
        runIndex,
        isLastShift,
        isLastRun,
        isOverview,
        isSilent,
      },
    ) {
      commit("setProperty", ["timeFrame", timeFrame]);
      commit("setProperty", ["date", date]);
      commit("setProperty", ["dateData", { hour, shiftIndex, runIndex }]);
      const currentBusinessDay = TimeUtils.getBusinessDateTime(
        DateTime.now().setZone(state.activeFactory.timezone),
        state.activeFactory.productionDayMinutesFromMidnight,
        state.activeFactory.isCalendarDayBusinessDay,
      );
      const currentHour = DateTime.now()
        .setZone(state.activeFactory.timezone)
        .set({ minute: 0, second: 0, millisecond: 0 })
        .get("hour");
      let isLiveData = false;
      let startDateTime = null;
      let endDateTime = null;

      /**
       * This is the only place where we set the value of isLiveData, and it is done every time we change time frames
       * or select a new date. No outside component has modifying access to isLiveData, and it is set based on the navigation
       * selection. Think of it as a computed property.
       *
       * This is intentional, and it is to avoid having multiple components set the value of isLiveData at different places
       * at different times, which makes maintenance and debugging much more difficult since there is no clear logic flow.
       *
       * If at any point, the value of isLiveData is not correct, this needs to be the first place to look since it is
       * the only place its value is set. The following is the business logic to determine the value of isLiveData.
       *
       * SCENARIO #1: Timeframe is HOUR
       *    isLiveData is true only if we are looking at the current hour
       * SCENARIO #2: Timeframe is SHIFT
       *    isLiveData is true only if we are looking at the current shift (in production or null shift, it must be the last shift selected aka. shiftIndex = 0 and isLastShift = true)
       * SCENARIO #3: Timeframe is PRODUCTION_DAY
       *    isLiveData is true only if we are looking at the current business day
       * SCENARIO #4: Timeframe is PRODUCTION_RUN
       *    isLiveData is true only if we are looking at the current production run (aka. runIndex = 0 and isLastRun = true)
       *
       * This way, we don't need to keep track of which component is updating isLiveData since there are none, it's automatically determined based
       * on the navigation selection that was made.
       *
       * PS: Overview shift
       * Selecting the SHIFT timeframe in the overview automatically sets the shift index to 0, since we can only look at the current shift in the Overview.
       * Consequently, isLiveData will always be true if looking at the current shifts in the overview.
       */

      switch (timeFrame) {
        case TimeUtils.HOUR: {
          // check if hour is live data
          isLiveData =
            date?.toFormat(TimeUtils.DATE_FORMAT) === currentBusinessDay.toFormat(TimeUtils.DATE_FORMAT) &&
            currentHour === hour;
          // show the entire hour
          const { start, end } = TimeUtils.getHourTimeRange(date, hour);
          startDateTime = start;
          endDateTime = end;
          break;
        }
        case TimeUtils.SHIFT: {
          // check if shift is the current one
          isLiveData = isLastShift;
          if (isOverview) {
            // get the last 24 hours
            const { start, end } = TimeUtils.getLast24Hrs(state.activeFactory.timezone);
            startDateTime = start;
            endDateTime = end;
          } else {
            // show the entire shift or until now
            let workShiftCoverage = rootGetters["dashboard/workShiftCoverage"];
            let selectedWorkShift = workShiftCoverage?.length > 0 ? workShiftCoverage[shiftIndex] : null;
            if (!selectedWorkShift) return;
            const { start, end } = TimeUtils.getWorkShiftTimeRange(selectedWorkShift, state.activeFactory.timezone);
            startDateTime = start;
            endDateTime = end;
          }
          break;
        }
        case TimeUtils.PRODUCTION_DAY: {
          // check if current production day
          isLiveData = date?.toFormat(TimeUtils.DATE_FORMAT) === currentBusinessDay.toFormat(TimeUtils.DATE_FORMAT);
          // show the entire production day
          const { start, end } = await TimeUtils.getProductionDayTimeRange(
            date.toFormat(TimeUtils.DATE_FORMAT),
            state.activeFactory.productionUnits[0].id,
            state.activeFactory.timezone
          );
          startDateTime = start;
          endDateTime = end;
          break;
        }
        case TimeUtils.PRODUCTION_RUN: {
          // check if production run is the current one
          isLiveData = isLastRun;
          // show the entire production run or until now
          let productionRunCoverage = rootGetters["dashboard/productionRunCoverage"];
          let selectedProductionRun = productionRunCoverage?.length > 0 ? productionRunCoverage[runIndex] : null;
          if (!selectedProductionRun) return;
          const { start, end } = TimeUtils.getProductionRunTimeRange(selectedProductionRun, state.activeFactory.timezone);
          startDateTime = start;
          endDateTime = end;
          break;
        }
      }
      dispatch("setCurrentDateTime");
      commit("setProperty", ["isLiveData", isLiveData]);
      commit("setProperty", ["startDateTime", startDateTime]);
      commit("setProperty", ["endDateTime", endDateTime]);
      commit("setOffsetFromMidnight");
      if (!isSilent) {
        // this property will be true if we wish to set the timeframe without refreshing views (aka. first load)
        commit("setProperty", ["isTimeRangeReady", !state.isTimeRangeReady]); // triggers views to re-fetch their data
      }
    },
    fetchFactoryDataSourceAlerts({ state, commit }) {
      if (state.activeFactory) {
        TileService.getDataSourceAlerts(state.activeFactory.id)
          .then((response) => {
            commit("setActiveFactoryDataSourceAlerts", response.data);
          })
          .catch((error) => {
            console.log(error);
            commit("setActiveFactoryDataSourceAlerts", []);
          });
      }
    },
    fetchFactoryUsers({ commit }, factoryId) {
      UserService.getUsers(factoryId)
        .then((httpResponse) => {
          commit("setFactoryUsers", httpResponse.data);
        })
        .catch((error) => {
          console.log(error);
          commit("setFactoryUsers", []);
        });
    },
    fetchFactoryTags({ commit }, factoryId) {
      FactoryService.getFactoryTags(factoryId)
        .then((httpResponse) => {
          commit("setFactoryTags", httpResponse.data);
        })
        .catch((error) => {
          console.log(error);
          commit("setFactoryTags", []);
        });
    },
    setCurrentDateTime({ state, commit }) {
      if (!state.activeFactory) return;
      const currentDateTime = DateTime.now().setZone(state.activeFactory.timezone);
      commit("setProperty", ["currentDateTime", currentDateTime]);
    },
    setOverviewShiftStarEndTimes({ state, commit }) {
      // this is only used when fetching the overview in shift mode since for the current shift we need to always pass
      // the last 24hrs to get the current shift.
      if (!state.activeFactory || state.timeFrame !== SHIFT) return;
      const { start, end } = TimeUtils.getLast24Hrs(state.activeFactory.timezone);
      commit("setProperty", ["startDateTime", start]);
      commit("setProperty", ["endDateTime", end]);
    },
  },

  mutations: {
    setActiveFactory(state, data) {
      state.activeFactory = data;
    },
    setActiveFactoryDataSourceAlerts(state, alerts) {
      state.activeFactoryDataSourceAlerts = alerts;
    },
    setFactoryUsers(state, value) {
      state.factoryUsers = value;
    },
    setFactoryTags(state, value) {
      state.factoryTags = value;
    },
    setProperty(state, [property, value]) {
      state[property] = value;
    },
    setOffsetFromMidnight(state) {
      const startAtMidnightMillis = state.startDateTime.set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).toMillis();
      const startMillis = state.startDateTime.toMillis();
      state.offsetFromMidnightMillis = startMillis - startAtMidnightMillis;
    },
  },
};
